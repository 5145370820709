@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "SharpGrotesk";
  font-stretch: normal;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Sharp-Grotesk-Medium"),
    url("https://cdn.xsolla.net/xsolla-login-widget/babka/Sharp-Grotesk-Medium.woff2 ")
      format("woff2");
}

@font-face {
  font-family: "SharpGrotesk";
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("Sharp-Grotesk-Medium"),
    url("https://cdn.xsolla.net/xsolla-login-widget/babka/Sharp-Grotesk-Medium.woff2 ")
      format("woff2");
}

@font-face {
  font-family: "SharpGrotesk";
  font-stretch: normal;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Sharp-Grotesk-Book"),
    url("https://cdn.xsolla.net/xsolla-login-widget/babka/Sharp-Grotesk-Book.woff2 ")
      format("woff2");
}

@font-face {
  font-family: Graphik;
  src: url("/fonts/Graphik/Graphik-Bold-Web.eot") format("embedded-opentype"),
    url("/fonts/Graphik/Graphik-Bold-Web.ttf") format("truetype"),
    url("/fonts/Graphik/Graphik-Bold-Web.woff") format("woff"),
    url("/fonts/Graphik/Graphik-Bold-Web.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Graphik;
  src: url("/fonts/Graphik/Graphik-Medium-Web.eot") format("embedded-opentype"),
    url("/fonts/Graphik/Graphik-Medium-Web.ttf") format("truetype"),
    url("/fonts/Graphik/Graphik-Medium-Web.woff") format("woff"),
    url("/fonts/Graphik/Graphik-Medium-Web.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Graphik;
  src: url("/fonts/Graphik/Graphik-Regular-Web.eot") format("embedded-opentype"),
    url("/fonts/Graphik/Graphik-Regular-Web.ttf") format("truetype"),
    url("/fonts/Graphik/Graphik-Regular-Web.woff") format("woff"),
    url("/fonts/Graphik/Graphik-Regular-Web.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Graphik;
  src: url("/fonts/Graphik/Graphik-Thin-Web.eot") format("embedded-opentype"),
    url("/fonts/Graphik/Graphik-Thin-Web.ttf") format("truetype"),
    url("/fonts/Graphik/Graphik-Thin-Web.woff") format("woff"),
    url("/fonts/Graphik/Graphik-Thin-Web.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
}

@layer base {
  html {
    font-family: SharpGrotesk, Proxima Nova, system-ui, sans-serif;
  }
}

.ReactModal__Overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content {
  transform: translateY(100vh);
  transition: transform 200ms ease-in-out;
}

.ReactModal__Content--after-open {
  transform: translateY(0);
}

.ReactModal__Content--before-close {
  transform: translateY(100vh);
}
